import React, { useEffect } from "react";
import Layout from "../components/layout";
// import Helmet from "react-helmet";
import Seo from "../components/seo";

import * as CONST from "../CONSTANTS";
import {
  MediaalphaPlaceholder,
  initMediaalphaWithZip,
} from "../components/thirdParty/mediaalpha";

const ExitOffers = ({ location }) => {
  const zip =
    location && location.state && location.state[CONST.CN_ZIP_CODE]
      ? location.state[CONST.CN_ZIP_CODE][CONST.DATA]
      : "auto";

  useEffect(() => {
    initMediaalphaWithZip(zip);
  }, []);

  return (
    <Layout layoutType={CONST.LAYOUT_ONLY_HEADER}>
      <Seo title="Offers" />
      <div className="container">
        <div className="col-12">
          <MediaalphaPlaceholder />
        </div>
      </div>
    </Layout>
  );
};

export default ExitOffers;
